import styled from 'styled-components';

import React from 'react';

import {ContentBoardMenu} from '@components/Menu';
import AggregatorTable from '@components/Tables/Aggregator';

import {InfoIcon} from '@Common/Images';
import TooltipCustom from '@Common/Tooltip';

import {UseNavigationList} from '@hooks/UseNavigationList';

const Title = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Aggregator = () => {
  UseNavigationList([
    {path: '/aggregator', title: 'Меры поддержки'},
    {path: '/aggregator', title: 'Список'},
  ]);
  return (
    <>
      <ContentBoardMenu
        pageTitle={
          <Title>
            Агрегатор мер поддержки
            <TooltipCustom
              content={
                'Для получения подробной информации о мере поддержке кликните по ней в списке ниже'
              }
              big>
              <InfoIcon />
            </TooltipCustom>
          </Title>
        }
      />
      <AggregatorTable />
    </>
  );
};

export default Aggregator;
