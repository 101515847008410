import {useQuery} from '@tanstack/react-query';
import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import {selectFilters} from '@store/filters/selectors';
import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {TechRequestService} from '@services/techRequest';

import {PaginationButtons} from '@components/Menu';

import FiltersPanel from '@Common/FiltersPanel';
import StatusChecker from '@Common/StatusChecker';
import Table from '@Common/Table';
import TableHeader from '@Common/Table/Header';
import {TableItem} from '@Common/Table/Item';
import {TableRowStyled} from '@Common/Table/Row';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {renameStatus} from '@utils/renameStatus';

import {Props} from './types';

const templateColumns =
  '20px minmax(200px, 1fr) minmax(200px, 1fr) minmax(125px, 1fr) minmax(125px, 1fr) minmax(350px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr)';

const TechRequestsTable = ({user, filters}: Props) => {
  const {search, page, createdBy, projectArea, techRequestStatus} = filters;
  const {is_supervisor, is_customer, is_project, id} = user;

  const [searchTerm, setSearchTerm] = useState('');
  const {
    data: techRequestList,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['techRequests', searchTerm, createdBy, page, projectArea, techRequestStatus],
    queryFn: () =>
      TechRequestService.getAll({
        searchTerm,
        createdBy,
        page,
        request_area: projectArea,
        status: techRequestStatus,
      }),
  });
  useEffect(() => {
    if (!search) setSearchTerm(search);
  }, [search]);

  const count = useMemo(() => techRequestList?.count, [techRequestList?.count]);

  return (
    <>
      <FiltersPanel count={count} isTechRequests handleEnterSearch={() => setSearchTerm(search)} />

      <StatusChecker errorsArray={[isError]} loadingsArray={[isLoading]}>
        <Table>
          <TableHeader
            templateColumns={templateColumns}
            titles={[
              {title: '№'},
              {title: 'Название'},
              {title: 'Автор'},
              {title: 'Статус'},
              {title: 'Предпосылки'},
              {title: 'Описание'},
              {title: 'Теги'},
              {title: 'Направление'},
            ]}
          />

          {techRequestList?.results.map((techRequest, techId) => {
            if (
              (techRequest.status === 'draft' || techRequest.status === 'declined') &&
              !is_supervisor &&
              !is_customer
            )
              return null;
            if (techRequest.status !== 'open' && is_project) return null;
            if (is_customer && id !== techRequest.created_by.id) return null;
            return (
              <TableItem key={techRequest.id} path={`/tech-requests/${techRequest.id}`}>
                <TableRowStyled $templateColumns={templateColumns}>
                  <TextParagraph color={Colors.textDark}>{techId + 1}</TextParagraph>
                  <TextParagraph color={Colors.accentBlue}>{techRequest.name}</TextParagraph>
                  <TextParagraph color={Colors.textDark}>
                    {techRequest.created_by.fio}
                  </TextParagraph>
                  <TextParagraph color={Colors.accentBlue}>
                    {renameStatus(techRequest.status || '-')}
                  </TextParagraph>
                  <TextParagraph color={Colors.textDark}>{techRequest.prerequisites}</TextParagraph>
                  <TextParagraph color={Colors.textDark}>{techRequest.description}</TextParagraph>
                  <TextParagraph color={Colors.textDark}>
                    {techRequest.tags.join(', ')}
                  </TextParagraph>
                  <TextParagraph color={Colors.textDark}>
                    {techRequest.request_area?.name || '-'}
                  </TextParagraph>
                </TableRowStyled>
              </TableItem>
            );
          })}
        </Table>
      </StatusChecker>
      <PaginationButtons margin={15} count={count} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
  filters: selectFilters(state),
});

export default connect(mapStateToProps)(TechRequestsTable);
