import styled from 'styled-components';

import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import ProjectItemStatus from 'components/DraftsItem/Status';
import React, {useCallback, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {ProjectService} from '@services/project';
import {ProjectRouterCheckListService} from '@services/projectRouterCheckList';

import {DraftsItemComments, DraftsItemScorings, DraftsItemStages} from '@components/DraftsItem';
import DraftsItemCustomer from '@components/DraftsItem/Customers';
import DraftsItemExpertise from '@components/DraftsItem/Expertise';
import DraftsItemInformations from '@components/DraftsItem/Informations';
import {DraftsItemParticipants} from '@components/DraftsItem/Participants';
import StagesRouterDraft from '@components/DraftsItem/Router';
import {ContentBoardMenu} from '@components/Menu';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {AddIcon, CheckIcon, RefreshIcon, TrashIcon, UploadIcon} from '@Common/Images';
import Loader from '@Common/Loader';
import {Modal} from '@Common/Modal';
import AcceptModal from '@Common/Modal/Accept';
import ChecklistDraftModal from '@Common/Modal/ChecklistDraft';
import TooltipCustom from '@Common/Tooltip';

import UseDraftData from '@hooks/UseDraft';
import UseMutationDraft from '@hooks/UseMutationDraft';
import UseMutationDeleteDraft from '@hooks/UseMutatuionDeleteDraft';
import {UseNavigationList} from '@hooks/UseNavigationList';

import {handleSubmitExport} from '@utils/handleExportProject';

import {IDraftsListItem} from 'types/state/Drafts';

import {IModals, StatePropsItem} from './types';

const InformationContainer = styled.div`
  display: flex;
  align-items: start;
  padding: 0;
  border-radius: 0 0 10px 10px;
  background: white;
  flex-grow: 1;
  gap: 16px;
`;

const DraftContentWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const DraftItem = ({user}: StatePropsItem) => {
  const queryClient = useQueryClient();
  const [modals, setModals] = useState<IModals>({
    modalContent: [],
    modalDescription: '',
    modalAccept: false,
    modalDelete: false,
    baseModal: false,
    checklistModal: false,
  });
  const pageId = Number(useParams().id);

  const {data, isErrorDraft, isLoadingDraft} = UseDraftData(pageId);

  const {is_project, is_superuser, is_supervisor} = user;

  const locationPathName = useLocation().pathname;

  const page = locationPathName.match(/\/(\w+)\//)?.[1];

  const isNotScoring = useMemo(
    () => !data?.scoring_status || data.scoring_status === 'В процессе',
    [data?.scoring_status],
  );
  const navigate = useNavigate();
  const mutation = UseMutationDraft(pageId);
  const deleteMutation = UseMutationDeleteDraft(pageId);

  const mutationCheckList = useMutation({
    mutationFn: async (id: number) => {
      await ProjectRouterCheckListService.delete(id);
    },

    onSuccess: () => {
      void queryClient.invalidateQueries(['project-router-checklist', pageId]);
      void queryClient.invalidateQueries([page, pageId]);
    },
  });

  const handleRouter = useCallback((data: IDraftsListItem | undefined) => {
    const content: string[] = [];

    if (!data?.description) {
      content.push('Заполнить описание');
    }
    if (!data?.project_area_id) {
      content.push('Заполнить направление');
    }
    if (!data?.presentation) {
      content.push('Загрузить презентацию');
    }
    if (!data?.lead_id) {
      content.push('Выбрать руководителя проекта');
    }

    if (!content.length) {
      setModals(prev => ({...prev, checklistModal: true}));
    } else {
      setModals(prev => ({
        ...prev,
        baseModal: true,
        modalContent: content,
        modalDescription: 'Для участия в маршрутизации необходимо:',
      }));
    }
  }, []);

  const handleDeleteChecklist = useCallback(() => {
    if (data?.checklist_id) mutationCheckList.mutate(data?.checklist_id);
  }, [data?.checklist_id, mutationCheckList]);

  const handleArchived = () => {
    mutation.mutate({
      archived: true,
    });
  };
  const handleProject = () => {
    mutation.mutate({
      in_work: true,
    });
    navigate('/project');
  };

  const mutationFinished = useMutation({
    mutationFn: (data: Pick<IDraftsListItem, 'id'>) => ProjectService.finishProject({id: data.id}),
    onSuccess: () => {
      void queryClient.invalidateQueries(['project']);
    },
  });

  const handleFinished = async () => {
    if (data && !data.finished) {
      await mutationFinished.mutateAsync({id: data.id});
    }
  };

  const handleDelete = useCallback(() => {
    deleteMutation.mutate();
    navigate('/draft');
  }, [deleteMutation, navigate]);

  UseNavigationList([
    {
      path: page === 'draft' ? '/draft' : '/project',
      title: page === 'draft' ? 'Заявки' : 'Проекты',
    },
    {path: window.location.pathname, title: data?.name || 'Loading...'},
  ]);

  if (isErrorDraft) return <div>Произошла ошибка</div>;
  if (isLoadingDraft || !data) return <Loader />;

  return (
    <DraftContentWrapper>
      <ContentBoardMenu pageTitle={data?.name}>
        {(is_superuser || is_supervisor) && !data.in_work && !isNotScoring && (
          <TooltipCustom content={'Добавить в проекты'}>
            <ContentBoardMenuButton
              action={handleProject}
              image={<RefreshIcon />}
              title={'В проекты'}
            />
          </TooltipCustom>
        )}
        {((data.is_draft && is_project) || is_supervisor || is_superuser) && !data.in_work && (
          <TooltipCustom
            content={
              !data.checklist_id
                ? 'Заполнить обязательный чек-лист (анкетирование) по вашей заявке'
                : ''
            }>
            <ContentBoardMenuButton
              isRed={!data.checklist_id}
              action={
                !data.checklist_id
                  ? () => handleRouter(data)
                  : () => setModals(prev => ({...prev, modalAccept: true}))
              }
              image={!data.checklist_id ? <RefreshIcon /> : <TrashIcon />}
              title={!data.checklist_id ? 'Далее' : 'Удалить чек-лист'}
            />
          </TooltipCustom>
        )}

        {!data.accelerator_id && data.in_work && (is_superuser || is_supervisor) && (
          <TooltipCustom content={!data.finished ? 'Завершить проект' : ''}>
            <ContentBoardMenuButton
              action={handleFinished}
              image={data.finished ? null : <CheckIcon />}
              title={data.finished ? 'Завершен' : 'Завершить'}
              isDisabled={data.finished}
            />
          </TooltipCustom>
        )}
        {!is_project && (
          <TooltipCustom content={'Экспортировать данные по текущей заявке в формате .zip (архив)'}>
            <ContentBoardMenuButton
              title={'Экспортировать'}
              action={() =>
                handleSubmitExport({
                  id: pageId,
                })
              }
              image={<UploadIcon />}
            />
          </TooltipCustom>
        )}

        {!data.archived && !data.in_work && (
          <TooltipCustom
            content={
              'Отправить текущую заявку в архив, если вы не планируете дальше реализовывать этот проект'
            }>
            <ContentBoardMenuButton
              action={handleArchived}
              image={<AddIcon />}
              title={'Удалить заявку'}
            />
          </TooltipCustom>
        )}
        {is_superuser && (
          <ContentBoardMenuButton
            action={() => setModals(prev => ({...prev, modalDelete: true}))}
            image={<TrashIcon />}
            title={'Удалить'}
          />
        )}
      </ContentBoardMenu>

      {data.in_work && <ProjectItemStatus data={data} />}
      <InformationContainer>
        <DraftsItemInformations data={data} />
        <DraftsItemParticipants data={data} />
      </InformationContainer>

      {data.in_work && <DraftsItemExpertise />}
      {data.in_work && <DraftsItemCustomer />}
      {data.checklist_id && <StagesRouterDraft />}

      {(isNotScoring && is_project) || data.is_draft ? null : <DraftsItemScorings />}

      {data.workflow_id && (
        <DraftsItemStages isFinished={data.finished} workflow_id={data.workflow_id} />
      )}

      <DraftsItemComments />

      {modals.baseModal && (
        <Modal
          description={modals.modalDescription}
          onClose={() => setModals(prev => ({...prev, baseModal: false}))}
          content={modals.modalContent}
        />
      )}

      {modals.checklistModal && page && (
        <ChecklistDraftModal
          handleYes={() => navigate(`/${page}/${pageId}/route`)}
          setIsOpen={() => setModals(prev => ({...prev, checklistModal: false}))}
        />
      )}

      {modals.modalAccept && (
        <AcceptModal
          handleYes={handleDeleteChecklist}
          setIsOpen={() => setModals(prev => ({...prev, modalAccept: false}))}
        />
      )}
      {modals.modalDelete && (
        <AcceptModal
          handleYes={handleDelete}
          setIsOpen={() => setModals(prev => ({...prev, modalDelete: false}))}
        />
      )}
    </DraftContentWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(DraftItem);
