import {NavLink, useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';
import React from 'react';

import {ProjectRouterStepListService} from '@services/projectRouterStepList';

import StatusChecker from '@Common/StatusChecker';
import Table from '@Common/Table';
import TableHeader from '@Common/Table/Header';
import {TableRowStyled} from '@Common/Table/Row';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {formatDateWithoutTime} from '@utils/formatDate';

const templateColumns = '150px minmax(200px, 1fr) 150px 150px minmax(125px, 1fr) ';

const DraftsStepsHistoryContent = () => {
  const pageId = Number(useParams().id);
  const {data, isLoading, isError} = useQuery({
    queryKey: ['support_measure', pageId],
    queryFn: async () => {
      return await ProjectRouterStepListService.getProjectRouterHistory(pageId);
    },
  });

  return (
    <Table>
      <TableHeader
        templateColumns={templateColumns}
        titles={[
          {title: 'Дата'},
          {title: 'Кто изменил'},
          {title: 'Предыдущий этап'},
          {title: 'Новый этап'},
          {title: 'Причина изменения'},
        ]}
      />
      <StatusChecker loadingsArray={[isLoading]} errorsArray={[isError]}>
        {data?.map(item => (
          <TableRowStyled key={item.id} $templateColumns={templateColumns}>
            <TextParagraph color={Colors.textDark}>
              {formatDateWithoutTime(item.created_at)}
            </TextParagraph>
            {item.created_by.is_related ? (
              <NavLink to={`/profile/${item.created_by_id}`}>
                <TextParagraph color={Colors.accentBlue}>
                  {item.created_by.fio || item.created_by.email1}
                </TextParagraph>
              </NavLink>
            ) : (
              <TextParagraph color={Colors.textDark}>
                {item.created_by.fio || item.created_by.email1}
              </TextParagraph>
            )}
            <TextParagraph color={Colors.textDark}>{item.previous_step_name}</TextParagraph>
            <TextParagraph color={Colors.textDark}>{item.previous_step_name}</TextParagraph>
            <TextParagraph color={Colors.textDark}>{item.change_cause}</TextParagraph>
          </TableRowStyled>
        ))}
      </StatusChecker>
    </Table>
  );
};

export default DraftsStepsHistoryContent;
