import styled from 'styled-components';

import {Link} from 'react-router-dom';

import React, {FormEvent, useState} from 'react';

import {AuthService} from '@services/auth';

import {StyledSmallLink} from '@components/DraftsItem/CheckList/Content/styles';

import AuthContainer from '@Common/AuthContainer';
import {LoginFormButton} from '@Common/Buttons';
import {LoginForm, LoginInput} from '@Common/Forms/Login';
import Loader from '@Common/Loader';
import {TextHeader, TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

export const AppLink = styled(Link)`
  text-decoration: none;
  color: rgb(23, 80, 203);
  align-items: center;
  display: flex;
  gap: 8px;

  &:hover {
    color: #1a225f;
  }

  & path {
    fill: #1750cb;
  }
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  gap: 15px;
`;

export const CheckboxInput = styled.input`
  margin: 0;
  border-radius: 5px;
  min-width: 20px;
  height: 20px;
  position: relative;
`;

const RegistrationPage = () => {
  const [error, setError] = useState('');

  const [userData, setUserData] = useState({
    fio: '',
    email1: '',
    phone1: '',
    is_internal: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [acceptPersonalData, setAcceptPersonalData] = useState(false);

  const [isHelpShown, setIsHelpShown] = useState(false);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (acceptPersonalData) {
      setIsLoading(true);

      await AuthService.registration(userData)
        .then(() => {
          setIsHelpShown(true);
        })
        .catch((e: Error) => {
          setError(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setError('Необходимо дать согласие на обработку персональных данных');
    }
  };

  const handleUpdateUserData = (field: keyof typeof userData) => (value: string | boolean) => {
    setUserData(prev => ({...prev, [field]: value}));
  };

  return (
    <AuthContainer>
      <TextHeader align="center" color="white">
        ИННОХАБ РОСАТОМА
        <br /> ПЛАТФОРМА МАРШРУТИЗАЦИИ ПРОЕКТОВ
      </TextHeader>
      <LoginForm onSubmit={(e: FormEvent) => onSubmit(e)}>
        <TextParagraph color={Colors.textDark}>Регистрация</TextParagraph>
        <LoginInput
          type="text"
          value={userData.fio}
          autoComplete={'on'}
          onChange={e => handleUpdateUserData('fio')(e.target.value)}
          placeholder={'ФИО'}
        />
        <LoginInput
          type={'email'}
          value={userData.email1}
          autoComplete={'on'}
          onChange={e => handleUpdateUserData('email1')(e.target.value)}
          placeholder={'Email'}
        />

        <LoginInput
          type={'text'}
          value={userData.phone1}
          autoComplete={'on'}
          onChange={e => handleUpdateUserData('phone1')(e.target.value)}
          placeholder={'Телефон'}
        />
        <CheckboxLabel>
          <CheckboxInput
            type="checkbox"
            onChange={e => handleUpdateUserData('is_internal')(e.target.checked)}
          />
          Являюсь сотрудником атомной отрасли
        </CheckboxLabel>

        <CheckboxLabel>
          <CheckboxInput type="checkbox" onChange={e => setAcceptPersonalData(e.target.checked)} />Я
          даю согласие на обработку персональных данных *
        </CheckboxLabel>
        <StyledSmallLink
          href={'https://copy.nextproject.online/upload/docs/consent_personal_data.docx'}>
          * Согласие на обработку персональных данных
        </StyledSmallLink>

        <LoginFormButton type="submit">Зарегистрироваться</LoginFormButton>
        {isLoading && <Loader />}
        {error && (
          <TextParagraph align="center" color={Colors.textDark}>
            {error}
          </TextParagraph>
        )}
        <AppLink to={'/auth'}>Войти</AppLink>
        <AppLink to={'/password_reset'}>Забыли пароль?</AppLink>
      </LoginForm>

      {isHelpShown && (
        <TextParagraph color={Colors.grey}>
          На указанную почту было выслано письмо с ссылкой для активации
        </TextParagraph>
      )}
    </AuthContainer>
  );
};

export default RegistrationPage;
