import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {useState} from 'react';
import {createPortal} from 'react-dom';

import {selectUser} from '@store/user/selectors';

import {ProjectRouterStepListService} from '@services/projectRouterStepList';

import {DraftsItemWrapper} from '@components/Wrappers/DraftsItem';

import {FilterButton} from '@Common/Buttons';
import {PanelHeader} from '@Common/DraftsItem/Panel';
import SingleDropdown from '@Common/Dropdown/Single';
import {DropdownSingleType} from '@Common/Dropdown/Single/types';
import {RoutesIcon} from '@Common/Images';
import {TextInput} from '@Common/Input';
import BaseModal from '@Common/Modal/Base';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseAppSelector from '@hooks/UseAppSelector';

import {IChangeProjectRouterStep} from 'types/state/ProjectRouterStepList';

import RouterContent from './Content';
import {stepListLabels} from './stepList';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StagesRouterDraft = () => {
  const queryClient = useQueryClient();
  const pageId = Number(useParams().id);
  const [isShownContent, setIsShownContent] = useState(false);
  const [shownChangeStepModal, setShownChangeStepModal] = useState(false);
  const [step, setStep] = useState<DropdownSingleType>(stepListLabels[0]);
  const [comment, setComment] = useState('');

  const {is_supervisor} = UseAppSelector(selectUser);
  const handleShow = () => {
    setIsShownContent(prev => !prev);
  };

  const mutationChangeStep = useMutation({
    mutationFn: (data: IChangeProjectRouterStep) =>
      ProjectRouterStepListService.changeProjectRouterStep(data),
    onSuccess: () => {
      void queryClient.invalidateQueries(['projectroutersteplist', pageId]);
    },
  });

  const changeStep = () => {
    setShownChangeStepModal(false);
    mutationChangeStep.mutate({
      project: pageId,
      new_step: Number(step?.value),
      change_cause: comment,
    });
  };

  return (
    <>
      <DraftsItemWrapper>
        <PanelHeader
          isShownContent={isShownContent}
          icon={<RoutesIcon />}
          title={'Этапы маршрутизации проекта'}
          action={handleShow}
          tooltip={
            'В данном разделе Вы можете видеть свой текущий этап развития Вашего проекта, а также предстоящие этапы в рамках процесса маршрутизации (дальнейшего развития)'
          }
          dopButton={
            is_supervisor ? (
              <FilterButton title="Изменить этап" action={() => setShownChangeStepModal(true)} />
            ) : null
          }
        />
        {isShownContent && <RouterContent />}
      </DraftsItemWrapper>

      {shownChangeStepModal &&
        createPortal(
          <BaseModal setIsOpen={setShownChangeStepModal}>
            <TextParagraph color={Colors.accentBlue}>
              Новый этап маршрутизации проекта
            </TextParagraph>
            <SingleDropdown
              isClearable={false}
              placeholder="Выберите этап"
              labels={stepListLabels}
              value={step}
              handleChange={setStep}
            />
            <TextParagraph color={Colors.accentBlue}>Укажите причину смены этапа</TextParagraph>
            <TextInput height={200} value={comment} onChange={e => setComment(e.target.value)} />
            <ButtonWrapper>
              {comment && <FilterButton width="50px" title="ок" action={changeStep} />}
            </ButtonWrapper>
          </BaseModal>,
          document.body,
        )}
    </>
  );
};

export default StagesRouterDraft;
