import {useParams} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';
import React, {useState} from 'react';
import {createPortal} from 'react-dom';

import {ScoringService} from '@services/scoring';

import {StyledAddButtonWrapper} from '@components/Wrappers/AddButtonWrapper';
import {PanelContentWrapper} from '@components/Wrappers/DraftsItem';

import {PanelButton} from '@Common/Buttons';
import StatusChecker from '@Common/StatusChecker';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseRoles from '@hooks/UseRoles';

import {ScoringsContentItem} from './Item';
import ScoringModal from './Modal';

const DraftsScoringsContent = () => {
  const pageId = Number(useParams().id);
  const [showModal, setShowModal] = useState(false);
  const {isSuperuser, isSupervisor, isProject, isCustomer} = UseRoles();

  const {
    data: scoringData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['scoring', pageId],
    queryFn: () => ScoringService.getByProject(pageId),
  });

  const scoringListMap = scoringData?.map(comment => {
    return <ScoringsContentItem key={comment.id} data={comment} />;
  });

  return (
    <StatusChecker errorsArray={[isError]} loadingsArray={[isLoading]}>
      <PanelContentWrapper>
        {scoringListMap?.length ? (
          scoringListMap
        ) : (
          <TextParagraph color={Colors.textDark}>Скоринги отсутствуют</TextParagraph>
        )}
      </PanelContentWrapper>
      {(!isProject || isSupervisor || isSuperuser) && !isCustomer && (
        <StyledAddButtonWrapper>
          {<PanelButton action={() => setShowModal(true)} />}
        </StyledAddButtonWrapper>
      )}
      {createPortal(
        <ScoringModal onClose={() => setShowModal(false)} isOpen={showModal} projectId={pageId} />,
        document.body,
      )}
    </StatusChecker>
  );
};

export default DraftsScoringsContent;
