import React from 'react';

import {titlesConcept} from '@components/CheckListCreate/ConceptProof';
import {titlesHypothesis} from '@components/CheckListCreate/HypothesisProof';
import {titlesInvestment} from '@components/CheckListCreate/InvestmentProject';
import {titlesScaling} from '@components/CheckListCreate/Scaling';

import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {IProjectRouterCheckListItem} from 'types/state/ProjectRouterCheckList';

import {StyledContentRow} from './styles';

interface QuestionList {
  [key: string]: string;
}

export const questions: Record<string, QuestionList> = {
  Масштабирование: titlesScaling,
  'Создание гипотезы': titlesHypothesis,
  'Разработка концепции': titlesConcept,
  'Инвестиционный проект': titlesInvestment,
};

interface IAnswers {
  data: IProjectRouterCheckListItem;
  type: keyof typeof questions;
}

const Answers = ({data, type}: IAnswers) => {
  return (
    <>
      {Object.keys(questions[type]).map(quiz => {
        const answer = data[quiz as keyof typeof data];
        return (
          <StyledContentRow key={quiz}>
            <TextParagraph color={Colors.grayTextOnDark}>{questions[type][quiz]}</TextParagraph>

            <TextParagraph color={Colors.grayTextOnDark}>
              {answer === null ? '-' : answer ? 'Да' : 'Нет'}
            </TextParagraph>
          </StyledContentRow>
        );
      })}
    </>
  );
};

export default Answers;
