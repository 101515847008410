export const reduceName = (name: string, count = 25) => {
  if (name && name.length > count) {
    return name.slice(0, count) + '...';
  }
  return name;
};

export const reduceValue = (value: number | null | undefined) => {
  if (value || value === 0) return value.toLocaleString('ru');
};
