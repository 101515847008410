export const isInternalLabels = [
  {
    value: 'true',
    label: 'Внутренний: Да',
  },
  {
    value: 'false',
    label: 'Внутренний: Нет',
  },
];

export const isExpertiseRequired = [
  {
    value: 'true',
    label: 'Требуется экспертиза',
  },
  {
    value: 'false',
    label: 'Экспертиза завершена',
  },
];

export const isAnswerRequired = [
  {
    value: 'true',
    label: 'Требуется ответ',
  },
  {
    value: 'false',
    label: 'Не требуется ответ',
  },
];

export const isArchivedLabels = [
  {
    value: 'true',
    label: 'Архивный: Да',
  },
  {
    value: 'false',
    label: 'Архивный: Нет',
  },
];

export const isFinishedLabels = [
  {
    value: 'true',
    label: 'Завершён: Да',
  },
  {
    value: 'false',
    label: 'Завершён: Нет',
  },
];

export const routerStagesLabels = [
  {
    value: '1',
    label: 'Масштабирование',
  },
  {
    value: '2',
    label: 'Инвестиционный проект',
  },
  {
    value: '3',
    label: 'Создание концепции',
  },
  {
    value: '4',
    label: 'Создание гипотезы',
  },
  {
    value: '5',
    label: 'Краткое описание',
  },
];

export const TechRequestStagesLabels = [
  {
    value: 'draft',
    label: 'На проверке',
  },
  {
    value: 'negotiation',
    label: 'Согласование',
  },
  {
    value: 'open',
    label: 'Открыт',
  },
  {
    value: 'hired',
    label: 'Выбран исполнитель',
  },
  {
    value: 'in_work',
    label: 'В работе',
  },
  {
    value: 'declined',
    label: 'Отклонён',
  },
  {
    value: 'finished',
    label: 'Завершён',
  },
  {
    value: 'active',
    label: 'Активный',
  },
  {
    value: 'selected',
    label: 'Согласован',
  },
];

export const scoringStatusLabels = [
  {
    value: 'in_progress',
    label: 'В процессе',
  },
  {
    value: 'accelerator_wait',
    label: 'Ожидание акселератора',
  },
  {
    value: 'revision',
    label: 'На доработку',
  },
  {
    value: 'recommendations',
    label: 'Выданы рекомендации',
  },
];

export const filterByUpdateLabels = [
  {value: '-created_at', label: 'по дате регистрации заявки'},
  {value: '-checklist_created_at', label: 'по дате публикации (прохождения чек-листа)'},
];

export const lastLoginLabels = [
  {
    value: '0',
    label: 'Не входил',
  },
  {
    value: '1',
    label: 'Текущий месяц',
  },
  {
    value: '2',
    label: 'Текущий квартал',
  },
  {
    value: '3',
    label: 'Текущий год',
  },
];

export const roleLabels = [
  {
    value: 'is_superuser',
    label: 'Админ',
  },
  {
    value: 'is_supervisor',
    label: 'Куратор',
  },
  {
    value: 'is_project',
    label: 'Инициатор',
  },
  {
    value: 'is_expert',
    label: 'Эксперт',
  },
  {
    value: 'is_hired',
    label: 'Исполнитель',
  },
  {
    value: 'is_customer',
    label: 'Бизнес-заказчик',
  },
  {
    value: 'is_observer',
    label: 'Наблюдатель',
  },
  {
    value: 'is_internal',
    label: 'Видит внутренние',
  },
];

export const projectCustomerLabels = [
  {
    value: 'true',
    label: 'Требуется решение по проектам',
  },
  {
    value: 'false',
    label: 'Все проекты',
  },
];
