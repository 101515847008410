import {MyOption} from 'components/Common/Dropdown/Single/types';

import {reduceName} from './stringFormat';

interface DataItem {
  id?: number;
  value?: string;
  name?: string;
  fio?: string;
  email1?: string;
  profile?: {
    fio: string;
    id: number;
  };
  project?: {
    name: string;
    id: number;
    color: string;
  };
}

export function transformDropdownData<T extends DataItem>(data: T[]): MyOption[] {
  return data.map(item => ({
    value: item.value || item.id?.toString() || '',
    label: reduceName(item.name || item.fio || item.profile?.fio || item.email1 || '', 40),
  }));
}
