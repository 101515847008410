import {styled} from 'styled-components';

export const StyledButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledButton = styled.button<{$isActive?: boolean}>`
  display: flex;
  cursor: pointer;
  padding: 10px;

  min-width: 50px;
  justify-content: center;
  border-radius: ${({theme}) => theme.borderRadius.full};
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit} `};

  background-color: ${({$isActive, theme}) =>
    $isActive ? theme.colors.accentBlue : theme.colors.white};
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
`;

export const FileInput = styled.input.attrs({
  type: 'file',
  accept: '.pdf,.doc,.docx,.txt,.ppt,.pptx, .xls, .xlsx',
})`
  display: none;
`;

export const Documents = styled.div<{$isDragging: boolean}>`
  border-radius: ${({theme}) => theme.borderRadius.full};
  border: ${({theme, $isDragging}) =>
    $isDragging ? `1px solid ${theme.colors.accentBlue}` : `1px solid ${theme.colors.greyCircuit}`};
  background: ${({theme}) => theme.colors.white};

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 75px;
  padding: 10px;
  cursor: pointer;
`;

export const StylesDocument = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const DeleteButton = styled.button`
  display: flex;
  cursor: pointer;

  background-color: transparent;
  border: none;
`;

export const StyledTextareaInput = styled.textarea<{$height?: number | null}>`
  color: var(--grey, #51575f);
  font-size: 16px;
  border: 0;
  outline: 0;
  width: 100%;

  resize: none;
  overflow-y: hidden;
  min-height: 250px;
  height: ${({$height}) => ($height ? `${$height}px` : '30px')};
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  padding: 5px 5px;
  align-items: center;
  width: 100%;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid ${({theme}) => theme.colors.greyCircuit};
  background: #fff;

  height: auto;
`;
