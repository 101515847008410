import styled from 'styled-components';

import React from 'react';

import {FilterButton} from '@Common/Buttons';
import ModalWrapper from '@Common/Modal/Wrapper';
import {Colors, TextParagraph} from '@Common/Text';

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface ModalProps {
  setIsOpen: (isOpen: boolean) => void;
  handleYes: () => void;
}

const ChecklistDraftModal = ({handleYes, setIsOpen}: ModalProps) => {
  const handleClickYes = () => {
    handleYes();
    setIsOpen(false);
  };

  return (
    <ModalWrapper>
      {/* <TextWrapper>
        <TextParagraph textIndent="30px" color={Colors.grey}>
          Чек-лист – анкетирование по Вашему проекту/идее, состоящее из вопросов с ответами
          «да/нет».
        </TextParagraph>
        <TextParagraph textIndent="30px" color={Colors.grey}>
          Чек-лист состоит из 4-х блоков вопросов, разделенных по принадлежности к этапам
          маршрутизации.
        </TextParagraph>
        <TextParagraph textIndent="30px" color={Colors.grey}>
          1 блок вопросов для проектов, находящихся на стадии «Масштабирование». Если вы ответите
          «да» на первый вопрос, то автоматически окажетесь на этом этапе и другие блоки Вам будут
          недоступны. Далее следует ответить на уточняющие вопросы блока «Масштабирование» и
          подтвердить результат.
        </TextParagraph>
        <TextParagraph textIndent="30px" color={Colors.grey}>
          Если Вы ответите «нет» на первый вопрос, то автоматически окажетесь на этапе ниже:
          «Инвестиционный проект». Далее по аналогии с предыдущим этапом: при ответе «да» Вам будет
          присвоен этот этап и предложены уточняющие вопросы, при ответе «нет» Вы переместитесь на
          этап ниже и так далее.
        </TextParagraph>
        <TextParagraph textIndent="30px" color={Colors.grey}>
          Таким образом, Вам необходимо ответить на вопросы только того блока, где на первый вопрос
          Вы выбираете «да».
        </TextParagraph>
        <TextParagraph textIndent="30px" color={Colors.grey}>
          Вы всегда можете вернуться к предыдущему блоку, если сомневаетесь в ответе, выбрав нужный
          пункт в меню.
        </TextParagraph>
        <TextParagraph textIndent="30px" color={Colors.grey}>
          При окончательном подтверждении чек-листа, он станет Вам недоступен для повторного
          прохождения.
        </TextParagraph>
      </TextWrapper> */}

      <TextWrapper>
        <TextParagraph textIndent="30px" color={Colors.grey}>
          Далее Вам предстоит пройти анкетирование по Вашей заявке.
        </TextParagraph>
        <TextParagraph textIndent="30px" color={Colors.grey}>
          Если Вы подаете заявку в акселерационную программу, ответьте «Да» на 1-ый вопрос, выберите
          нужный акселератор и заполните необходимую информацию.
        </TextParagraph>
        <TextParagraph textIndent="30px" color={Colors.grey}>
          В другом случае выберите ответ «Нет» на 1-ый вопрос и ответьте на последующие вопросы для
          участия в дальнейшей маршрутизации Вашей заявки на платформе.
        </TextParagraph>
        <TextParagraph textIndent="30px" color={Colors.grey}>
          Будьте внимательны при выборе ответов!
        </TextParagraph>
        <TextParagraph textIndent="30px" color={Colors.grey}>
          Самостоятельно изменить ответы после подтверждения результатов анкеты будет невозможно,
          для этого обратитесь к куратору Вашей заявки
        </TextParagraph>
      </TextWrapper>

      <ButtonWrapper>
        <div></div>
        <FilterButton width="200px" isBlue={false} title={'Подтвердить'} action={handleClickYes} />
      </ButtonWrapper>
    </ModalWrapper>
  );
};

export default ChecklistDraftModal;
